<template>
  <b-card title="GST List">
    <b-col cols="12">
      <b-row class="mb-2">
        <b-col cols="4">
          <b-button v-if="permission.add" @click="toAddGST" variant="success">
            Add GST List
          </b-button>
        </b-col>
        <b-col cols="4">
          <!-- <b-button @click="toCheckStockAPI" variant="success">
                        To Check Stock API
                    </b-button> -->
        </b-col>
        <!-- <b-col cols="4"/> -->
        <b-col cols="4">
          <b-form-input
            v-model="search"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
            :fields="fields"
            :filter="search"
            :current-page="currentPage"
            :per-page="perPage"
            :items="gst"
            show-empty
          >
            <template #cell(startDate)="row">
              {{ dates(row.item.startDate) }}
            </template>
            <template #cell(actions)="row">
              <b-dropdown
                id="dropdown-dropleft"
                variant="primary"
                size="sm"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item
                  style="text-align:center"
                  v-if="permission.edit"
                >
                  <b-button
                    variant="outline-primary"
                    @click="toEditGST(row.item._id)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item
                  style="text-align:center"
                  v-if="permission.delete"
                >
                  <b-button variant="danger" @click="doDeleteGST(row.item._id)">
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <b-pagination
            :total-rows="gst.length"
            :per-page="perPage"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapActions,mapState } from "vuex";
import { userAccess } from "@/utils/utils";
import { dateFormat } from "@/utils/utils";

export default {
  data() {
    return {
      fields: [
        { key: "name", label: "Name", filterable: true, sortable: true },
        {
          key: "startDate",
          label: "Start Date",
          filterable: true,
          sortable: true,
        },
        { key: "value", label: "Value(%)", filterable: true, sortable: true },
        { key: "actions", label: "Action" },
      ],
      gstData: [],
      search: "",
      currentPage: 1,
      perPage: 10,
    };
  },
  mounted() {
    this.$store.dispatch("GST/fetchGST");
  },
  methods: {
    dates(date) {
      return dateFormat(date);
    },
    ...mapActions({
      listCompressorWarranty: "warranty/listCompressorWarranty",
      deleteCompressorWarranty: "warranty/deleteCompressorWarranty",
    }),
    pullCompressorWarranty() {
      this.listCompressorWarranty().then((data) => {
        this.compressorWarranty = data;
      });
    },
    toAddGST() {
      this.$router.push("/settings/gst-add");
    },
    doDeleteGST(id) {
      var deleteID=id;
      confirm("Are You sure want to delete this GST ?");
      this.$store
        .dispatch("GST/deleteGST", {id:id})
        .then(() => {
          this.$store.dispatch("GST/fetchGST");
        })
        .catch((err) => {
          console.error({ err });
        });
    },
    toEditGST(id) {
      this.$router.push(`/settings/gst-edit/${id}`);
    },
    toCheckStockAPI() {
      this.$router.push("/settings/checkstock");
    },
  },
  computed: {
    ...mapState({
      gst: (state) => state.GST.gst,
    }),
    permission() {
      console.log("userAccess", userAccess("GST", "settings_menu"));
      return userAccess("GST", "settings_menu");
    },
    gst() {
      return this.$store.getters["GST/getGST"];
    },
    dataSearch() {
      console.log("this.holiday", this.holidays);
      let paginationLength;
      paginationLength = this.gst.filter((el) => {
        return el.name.includes(this.search);
      });
      if (this.search != "") {
        return paginationLength.length;
      } else {
        return this.gst.length;
      }
    },
  },
  created() {
    document.title = "GST | RSP";
  },
};
</script>
